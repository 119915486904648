<template>
  <div>
    <div ref="dataWater" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
  import echarts from 'echarts'
  import echartsLiquidfill from 'echarts-liquidfill'

  let option = {
    series: [{
      type: 'liquidFill',
      data: [{
        labelText: 0,
        value: 0,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: .3,
            y2: 1,
            colorStops: [{
              offset: 0, color: '' // 0% 处的颜色
            }, {
              offset: 1, color: '' // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }
        }
      }],
      backgroundStyle: {
        color: '#242836'
      },
      // waveAnimation: false,
      outline: {
        // show: true , //是否显示轮廓 布尔值
        borderDistance: 0, //外部轮廓与图表的距离 数字
        itemStyle: {
          borderColor: '', //边框的颜色
          borderWidth: 2,  //边框的宽度
          //shadowBlur: 5 , //外部轮廓的阴影范围 一旦设置了内外都有阴影
          //shadowColor: '#000' //外部轮廓的阴影颜色
        }
      },
      center: ['50%', '50%'],
      radius: '80%',
      label: {
        position: ['50%', '50%'],
        formatter: function (res) {
          return res.data.labelText
        },
        fontSize: 25,
        color: '#fff',
        fontWeight: 'normal',
      }
    }]
  }
  export default {
    props: {
      waterOption: {
        default: function () {
          return {}
        },
        type: Object
      }
    },
    data () {
      return {}
    },
    watch: {
      'waterOption': {
        handler () {
          this.initWaterTheBall()
        },
        deep: true,
        // immediate: true
      }
    },
    methods: {
      initWaterTheBall () {
        let dataWater = echarts.init(this.$refs.dataWater)
        // if (!this.waterOption.proportion) return
        // console.log(this.waterOption)
        option.series[0].data[0].value = this.waterOption.proportion
        option.series[0].data[0].labelText = this.waterOption.value
        option.series[0].data[0].itemStyle.color.colorStops[0].color = this.waterOption.color[0]
        option.series[0].data[0].itemStyle.color.colorStops[1].color = this.waterOption.color[1]
        option.series[0].outline.itemStyle.borderColor = this.waterOption.borderColor
        dataWater.setOption(option)
        window.addEventListener('resize', function () {
          dataWater.resize()
        })
      }
    },
    mounted () {
      // this.initWaterTheBall()
      // console.log(this.waterOption)
    }
  }
</script>

<style lang='sass' scoped>
</style>
